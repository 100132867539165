import React, { useEffect, useContext, useState } from "react"
import { graphql, PageProps } from "gatsby"
import { BasicContentTemplatePageData, TemplateProps } from "../templates"
import BasicContentTemplate from "../../components/BasicContentTemplate/BasicContentTemplate"
import { SEO } from "../../components/SEO"

const BasicContent: React.FC<PageProps> = ({
  data,
  location,
}: TemplateProps<BasicContentTemplatePageData>) => {
  const { title, content, featuredImage, template } = data.wpPage
  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <BasicContentTemplate
        content={content}
        featuredImage={featuredImage}
        template={template}
      />
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      template {
        ... on WpTemplate_BasicContent {
          templateName
          basicContentPage {
            basicContentHero {
              crown
              headline
              cta {
                title
                url
              }
              highlightCards {
                description
                header
              }
            }
          }
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default BasicContent
