import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import EconomicStats from "../EconomicStats.tsx/EconomicStats"
import { Image } from "../Image"

interface IStatisticsHero {
  template: any
  featuredImage: any
}

const StatisticsHero: React.FC<IStatisticsHero> = ({
  template,
  featuredImage,
}) => {
  const imageSrc = getImage(featuredImage?.node?.localFile)
  const templateHeading = template?.basicContentPage?.basicContentHero
  const heading = templateHeading?.headline
  const crown = templateHeading?.crown

  const [text, setText] = useState({ heading, crown })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ heading, crown })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([heading, crown])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <section css={[tw`text-center relative h-96`, templateHeading?.highlightCards?.length > 0 && tw`h-auto`]}>
      <Image
        src={imageSrc}
        publicURL={featuredImage?.node?.localFile?.publicURL}
        aria-hidden
        alt=""
        css={[
          tw`absolute left-0 top-0 h-full w-full -z-1 object-center object-cover`,
        ]}
      />
      <div css={[tw`w-full max-w-8xl m-auto flex flex-col text-grayBlack`]}>
        <p
          css={[
            tw`text-white text-base mb-4 mt-32 sm:w-full w-72 mx-auto font-medium`,
          ]}
          dangerouslySetInnerHTML={{ __html: text?.crown }}
        />
        <h2
          css={[
            tw`text-white sm:text-6xl text-5xl font-extrabold mt-3 sm:w-full w-72 mx-auto`,
          ]}
          dangerouslySetInnerHTML={{ __html: text?.heading }}
        />
        {templateHeading?.highlightCards && <EconomicStats highlightCards={templateHeading?.highlightCards} />}
      </div>
    </section>
  )
}

export default StatisticsHero
