import React, { useContext, useEffect, useState } from "react"
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
import tw from "twin.macro"
import StatisticsHero from "../StatisticsHero/StatisticsHero"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { BasicContentPageTemplateProps } from "./BasicContentTemplate.d"

const BasicContentTemplate: React.FC<BasicContentPageTemplateProps> = ({
  content,
  featuredImage,
  template,
}) => {
  const [mainContent, setMainContent] = useState({ content })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setMainContent({ content })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([content])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(mainContent, translations)
        setMainContent(translated)
      })()
    }
  }, [language])

  return (
    <section>
      <StatisticsHero template={template} featuredImage={featuredImage} />
      <div
        css={[
          tw`w-full flex flex-col text-grayBlack bg-cover bg-top bg-no-repeat absolute h-52 md:h-96 lg:h-160 xl:h-200`,
        ]}
        style={{ backgroundImage: "url('cloudFade.webp')" }}
      />
      <div css={[tw`pt-16 z-10 relative`]}>
        <p
          css={[
            tw`text-lg font-medium px-8 space-y-8 m-auto max-w-525 sm:max-w-640 lg:max-w-768 xl:max-w-1024`,
          ]}
          dangerouslySetInnerHTML={{ __html: mainContent.content }}
        />
      </div>
    </section>
  )
}

export default BasicContentTemplate
