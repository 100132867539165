import React from "react"
import tw from "twin.macro"

interface IEconomicStats {
  highlightCards: any;
}

const EconomicStats: React.FC<IEconomicStats> = ({ highlightCards }) => {
  return (
    <ul
      css={[
        tw`sm:my-20 my-6 grid grid-cols-2 lg:grid-cols-3 xxs:gap-x-4 gap-x-3 gap-y-2 lg:gap-y-8 xl:gap-x-16 xl:gap-y-10 xs:pl-10 pl-2 pr-2 xs:pr-10 lg:pl-60 lg:pr-60`,
      ]}
    >
      {highlightCards?.map(({ description, header }, index) => (
        <li
          css={tw`flex flex-col justify-between bg-blue-1000 bg-opacity-50 pt-6 pb-0`}
          key={index}
        >
          <div css={[tw`flex flex-col justify-center`]}>
            <h2 css={[tw`text-white sm:text-5xl text-4xl font-extrabold mb-2`]} dangerouslySetInnerHTML={{ __html: header }} />
            <p css={[tw`text-white sm:text-base text-sm px-2`]} dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <div css={[tw`w-full bg-white h-1 mt-3`]}></div>
        </li>
      ))
      }
    </ul >
  )
}

export default EconomicStats
